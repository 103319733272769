@font-face {
  font-family: 'custom';
  src: url('../public/fonts/Helvetica.ttf') format('truetype');
}

@font-face {
  font-family: 'custombold';
  src: url('../public/fonts/Helvetica-Bold.ttf') format('truetype');
}

body {
  padding: 0;
  margin: 0;
  font-family: "custom" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --toastify-font-family: "custom" !important;
}

h1,
h2,
h3 {
  font-family: "custombold" !important;
  /* font-weight: 700; */
}

.customfont {
  font-family: 'custombold', sans-serif !important;
}

.twoLineText {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  /* This is the standard property */
  -webkit-box-orient: vertical;
  height: 2.8em;
  /* Adjust height based on your font-size and line-height */

}

.slick-prev {
  left: 30px;
  /* top: 200px; */
  /* background: rgb(227, 227, 227); */
  /*to notice it, is white*/
  z-index: 1;
  font-size: 32px;
}

.slick-next {
  right: 50px;
  font-size: 32px; 
}

.slick-next::before,
.slick-prev::before { 
  font-size: 32px; 
}

.image-container {
  position: relative;
  display: inline-block;
}

.cart-count {
  position: absolute;
  top: -10px; /* Adjust as needed */
  left: 10px; /* Adjust as needed */
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 8px;
  font-size: 12px; /* Adjust as needed */
  /* font-weight: bold; */
}